import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-nightwatch",
  "date": "2016-06-27",
  "title": "REACT & NIGHTWATCH",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Nightwatch is an end-to-end test framework. The reasons nightwatch is your best choice can be decided upon the reasons described in this article."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The reasons nightwatch is your best choice can be decided upon the reasons given below:`}</p>
    <ul>
      <li parentName="ul">{`Written in Node.js and integrates well with front-end stack.`}</li>
      <li parentName="ul">{`Has a built-in test runner, where you can run your tests in `}<em parentName="li">{`parallel`}</em>{`.`}</li>
      <li parentName="ul">{`Easier to integrate with CI environments like Jenkins or TeamCity`}</li>
      <li parentName="ul">{`Test reports & screenshots`}</li>
    </ul>
    <p>{`In this article, I’ll guide you through the setup process.`}</p>
    <h2>{`Installation`}</h2>
    <p>{`We need Node.js installed to begin with. Follow the link, download `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/download/"
      }}>{`Node.js`}</a>{` for installation instructions.`}</p>
    <p>{`Now create a folder for your project and then run the following command in command line.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm init
`}</code></pre>
    <p>{`Next, we need to install `}<inlineCode parentName="p">{`nightwatch`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install nightwatch ––save–dev
`}</code></pre>
    <p>{`Now, in order to run tests, we need to download the `}<inlineCode parentName="p">{`Selenium standalone server`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install selenium-download ––save–dev
`}</code></pre>
    <p>{`We also need to paste the line below into the `}<inlineCode parentName="p">{`selenium-download.js`}</inlineCode>{` file.`}</p>
    <p>{`Add the following entry to your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file’s `}<inlineCode parentName="p">{`scripts`}</inlineCode>{` section.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"test": "./node_modules/.bin/nightwatch"
`}</code></pre>
    <h3>{`Selenium`}</h3>
    <p>{`To download the `}<inlineCode parentName="p">{`Selenium standalone server`}</inlineCode>{`, run the following command in the command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install selenium-download ––save–dev
`}</code></pre>
    <p>{`Now create a file called `}<inlineCode parentName="p">{`selenium-download.js`}</inlineCode>{` and paste the line of code below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var selenium = require('selenium-download');
selenium.ensure('./bin', function(error) {
   if (error) {
      return callback(error);
   }
});
`}</code></pre>
    <p>{`We will also add a scripts command to run the scripts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"scripts": {
  "setup": "node selenium-download.js"
}
`}</code></pre>
    <p>{`Now you run the following command in the command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm run setup
`}</code></pre>
    <p>{`This will download the latest version of selenium.`}</p>
    <h2>{`Configuration`}</h2>
    <p>{`In order to configure Nightwatch, we need to create a `}<inlineCode parentName="p">{`nightwatch.json`}</inlineCode>{` as the file for the test runs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "src_folders": ["tests"],
  "output_folder": "reports",
  "custom_commands_path": "",
  "custom_assertions_path": "",
  "page_objects_path": "pages",
  "globals_path": "globals.js",
 
  "selenium": {
    "start_process": true,
    "server_path": "./bin/selenium.jar",
    "log_path": "./reports",
    "host": "127.0.0.1",
    "port": 4444,
    "cli_args": {
      "webdriver.chrome.driver": "./bin/chromedriver"
    }
  },
  "test_settings": {
    "default": {
      "launch_url": "",
      "selenium_port": 4444,
      "selenium_host": "localhost",
      "silent": true,
      "desiredCapabilities": {
        "browserName": "chrome",
        "javascriptEnabled": true,
        "acceptSslCerts": true
      }
    }
  }
}
`}</code></pre>
    <p>{`Let’s have a close look at this configuaration file.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`src_folders`}</inlineCode>{` – The tests will be in this array of folders`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`out_folder`}</inlineCode>{` – Test artifacts folder. (Reports, logs and screenshots)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`page_objects_path`}</inlineCode>{` – The Page Objects will be defined here.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`globals_path`}</inlineCode>{` – Global variables path will be stored here`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`selenium`}</inlineCode>{` – Settings for Selenium.`}</li>
    </ul>
    <Message type="info" title="" content="It is important to have the `starter_process` set to `true` so that selenium server will start straight away." mdxType="Message" />
    <p>{`The setting `}<inlineCode parentName="p">{`desiredCapabilities`}</inlineCode>{` will make sure Chrome as will be the browser running our tests.`}</p>
    <h2>{`ES6 Support`}</h2>
    <p>{`We will create a `}<inlineCode parentName="p">{`nightwatch.conf.js`}</inlineCode>{` file in the root, in order add few lines.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`require('babel-core/register');
 
module.exports = require('./nightwatch.json');
`}</code></pre>
    <p>{`Now we can run our tests in ES6.`}</p>
    <p>{`Tests
Create a tests directory in the root folder and add the test script called login.js.`}</p>
    <p>{`Now we need to save the login credentials of your preferred site to test. Open up a new terminal session and enter the commands below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`export USERNAME=”your username”
export PASSWORD=”your password”
`}</code></pre>
    <Message type="warn" title="Windows" content="If you are in windows, use `set` instead of `export`." mdxType="Message" />
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`export default {
  'Log in': (user) => {
    const loginPage = client.page.login();
    const instancesPage = client.page.instancesPage();
 
    loginPage
        .navigate()
        .login(process.env.USERNAME, process.ENV.PASSWORD);
 
    instancesPage.expect.element('');
    user.end();
  }
};
`}</code></pre>
    <p>{`Let’s look at what’s going on in the code.`}</p>
    <ul>
      <li parentName="ul">{`User navigates to the login page.`}</li>
      <li parentName="ul">{`User logs in using the credentials, saved in the environemnt before.`}</li>
      <li parentName="ul">{`The test asserts an element to exist upon login.`}</li>
      <li parentName="ul">{`The line, `}<inlineCode parentName="li">{`client.end()`}</inlineCode>{` method will end the browser session.`}</li>
    </ul>
    <p>{`For non-technical people to understand the steps, we need use the `}<inlineCode parentName="p">{`Page Object`}</inlineCode>{` pattern.`}</p>
    <h2>{`Page Object`}</h2>
    <p>{`Let’s create a folder called `}<inlineCode parentName="p">{`pages`}</inlineCode>{` for page objects and add a `}<inlineCode parentName="p">{`login.js`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const loginCommands = {
  login(email, pass) {
    return this
      .waitForElementVisible('@emailInput')
      .setValue('@emailInput', email)
      .setValue('@passInput', pass)
      .waitForElementVisible('@loginButton')
      .click('@loginButton')
  }
};
 
export default {
  url: 'https://tutsplus.com/sign_in',
  commands: [loginCommands],
  elements: {
    emailInput: {
      selector: 'input[type=text]'
    },
    passInput: {
      selector: 'input[name=password]'
    },
    loginButton: {
      selector: 'button[type=submit]'
    }
  }
};
`}</code></pre>
    <h2>{`Global Config`}</h2>
    <p>{`Nightwatch requires a timeout parameter to be passed in, so the test throws an error when the timeout limit is hit.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`waitForElementVisible('@anElement', 3000)
`}</code></pre>
    <p>{`We will create a `}<inlineCode parentName="p">{`global.js`}</inlineCode>{` file in the root of your project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export default {
  waitForConditionTimeout: 10000,
};
`}</code></pre>
    <p>{`Now the Nightwatch methods will have a 10 second timeout.`}</p>
    <h2>{`Run Tests`}</h2>
    <p>{`Now you can run tests by typing the following command in the command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`nightwatch
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      